var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "usercerti" },
    [
      _c("headbox", { attrs: { title: "实名认证", bgColor: "#39394d" } }),
      _c("div", { staticClass: "cerbox usercenter" }, [
        _c("div", { staticClass: "tip" }, [
          _vm._v(" 为保证您的账户安全，请先完成实名认证 ")
        ]),
        _c("div", { staticClass: "radius" }, [
          _c("h1", [_vm._v("请绑定银行卡")]),
          _c(
            "cite",
            [
              _c("p", [_vm._v("真实姓名")]),
              _c("el-input", {
                attrs: { placeholder: "请输入真实姓名", disabled: "" },
                model: {
                  value: _vm.formdata.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "name", $$v)
                  },
                  expression: "formdata.name"
                }
              })
            ],
            1
          ),
          _c(
            "cite",
            [
              _c("p", [_vm._v("身份证号")]),
              _c("el-input", {
                attrs: { placeholder: "请输入身份证号", disabled: "" },
                model: {
                  value: _vm.formdata.idNum,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "idNum", $$v)
                  },
                  expression: "formdata.idNum"
                }
              })
            ],
            1
          ),
          _c(
            "cite",
            [
              _c("p", [_vm._v("银行卡号")]),
              _c(
                "el-input",
                {
                  attrs: {
                    placeholder: "请输入有效的银行卡号",
                    maxlength: "19"
                  },
                  model: {
                    value: _vm.formdata.bankNo,
                    callback: function($$v) {
                      _vm.$set(_vm.formdata, "bankNo", $$v)
                    },
                    expression: "formdata.bankNo"
                  }
                },
                [
                  _c(
                    "van-uploader",
                    {
                      ref: "uploader",
                      attrs: {
                        slot: "suffix",
                        "after-read": _vm.afterRead,
                        "preview-image": false
                      },
                      slot: "suffix"
                    },
                    [_c("div", { staticClass: "uploadIcon" })]
                  )
                ],
                1
              ),
              _vm.isloading
                ? _c("div", [
                    _c("div", { staticClass: "bgfilter" }),
                    _c(
                      "div",
                      { staticClass: "tc loadingbox" },
                      [
                        _c("van-loading", { attrs: { size: "26px" } }, [
                          _c("span", { staticClass: "loadingfont" }, [
                            _vm._v("上传中...")
                          ])
                        ])
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "cite",
            [
              _c("p", [_vm._v("银行名称")]),
              _c("el-input", {
                attrs: { placeholder: "请输入银行名称" },
                model: {
                  value: _vm.formdata.bankName,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "bankName", $$v)
                  },
                  expression: "formdata.bankName"
                }
              })
            ],
            1
          ),
          _c(
            "cite",
            [
              _c("p", [_vm._v("所属支行")]),
              _c("el-input", {
                attrs: { placeholder: "请输入支行名称" },
                model: {
                  value: _vm.formdata.bankAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.formdata, "bankAddress", $$v)
                  },
                  expression: "formdata.bankAddress"
                }
              })
            ],
            1
          ),
          _vm._m(0)
        ]),
        _c(
          "div",
          { staticClass: "tc" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn_red",
                style: { background: _vm.confirm ? "#39394d" : "#39394d" },
                attrs: { disabled: !_vm.confirm },
                on: { click: _vm.postData }
              },
              [_vm._v("提交")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("cite", [
      _c("div", { staticClass: "text" }, [
        _vm._v(
          " 请填写银行卡信息,该信息主要用于资金发放,为保证及时性,请务必填写正确，且为本人银行账号。 "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }